// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group off-canvas
////

/// Width of an off-canvas menu.
/// @type Number
$offcanvas-size: 250px;

/// Background color of an off-canvas menu.
/// @type Color
$offcanvas-background: $light-gray;

/// Z-index of an off-canvas menu.
/// @type Number
$offcanvas-zindex: -1;

/// Length of the animation on an off-canvas menu.
/// @type Number
$offcanvas-transition-length: 0.5s;

/// Timing function of the animation on an off-canvas menu.
/// @type Keyword
$offcanvas-transition-timing: ease;

/// Background color for the overlay that appears when an off-canvas menu is open.
/// @type Color
$offcanvas-exit-background: rgba($white, 0.25);

/// CSS class used for the main content area. The off-canvas mixins use this to target the page body.
$maincontent-class: 'off-canvas-content';

/// Box shadow to place under the main content area. This shadow overlaps the off-canvas menus.
/// @type Shadow
$maincontent-shadow: 0 0 10px rgba($black, 0.5);

/// Boolean to prevent scroll on offcanvas open
/// @type Boolean
$maincontent-prevent-scroll: true !default;

/// Adds baseline styles for off-canvas. This CSS is required to make the other pieces work.
@mixin off-canvas-basics {
  // Extra properties needed on <html> and <body> to make off-canvas work
  html,
  body {
    height: 100%;
  }

  .off-canvas-wrapper {
    width: 100%;
    overflow-x: hidden;
    position: relative;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .off-canvas-wrapper-inner {
    @include clearfix;
    position: relative;
    width: 100%;
    transition: transform $offcanvas-transition-length $offcanvas-transition-timing;

    @if $maincontent-prevent-scroll == true {
      &.is-off-canvas-open {
        overflow-y: hidden;
      }
    }
  }

  // Container for page content
  .off-canvas-content {
    min-height: 100%;
    background: $body-background;
    transition: transform $offcanvas-transition-length $offcanvas-transition-timing;
    backface-visibility: hidden;
    z-index: 1;

    @if hasvalue($maincontent-shadow) {
      box-shadow: $maincontent-shadow;
    }
  }

  // Click-to-exit overlay (generated by JavaScript)
  .js-off-canvas-exit {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $offcanvas-exit-background;
    cursor: pointer;
    transition: background $offcanvas-transition-length $offcanvas-transition-timing;

    .is-off-canvas-open & {
      display: block;
    }
  }
}

/// Adds basic styles for an off-canvas menu.
@mixin off-canvas-base {
  @include disable-mouse-outline;
  position: absolute;
  background: $offcanvas-background;
  z-index: $offcanvas-zindex;
  min-height: 100%;
  transform: translateX(0px);
}

@mixin off-canvas-position(
  $position: left,
  $size: $offcanvas-size,
  $fixed: false
) {
  @if $position == left {
    left: -$size;
    top: 0;
    width: $size;
  }
  @else if $position == right {
    right: -$size;
    top: 0;
    width: $size;
  }

  // Generates an open state class that matches the width of the menu
  @at-root {
    .is-open-#{$position} {
      @if $position == left {
        transform: translateX($size);
      }
      @else if $position == right {
        transform: translateX(-$size);
      }
    }
  }
}

/// Adds styles that reveal an off-canvas menu.
/// @param {Keyword} $position [left] - Position of the off-canvas menu being revealed.
@mixin off-canvas-reveal(
  $position: left
) {
  #{$position}: 0;

  & ~ .#{$maincontent-class} {
    margin-#{$position}: $offcanvas-size;
  }
}

@mixin foundation-off-canvas {
  @include off-canvas-basics;

  // Off-canvas container
  .off-canvas {
    @include off-canvas-base;

    &.position-left   { @include off-canvas-position(left); }
    &.position-right  { @include off-canvas-position(right); }
  }

  // Reveal off-canvas menu on larger screens
  @each $name, $value in $breakpoint-classes {
    @if $name != small {
      @include breakpoint($name) {
        .position-left.reveal-for-#{$name} {
          @include off-canvas-reveal(left);
        }
        .position-right.reveal-for-#{$name} {
          @include off-canvas-reveal(right);
        }
      }
    }
  }
}
