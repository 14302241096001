body > main {

    & > section,
    & > article {

        @include grid-row;
        padding-top:    $grid-column-gutter/2;
        padding-bottom: $grid-column-gutter/2;

        & > h1, & > header {
            @include grid-col(12);

            p {
                @extend .lead;
                font-style: italic;
            }
        }

        & > section {
            @include grid-col(12);
        }
    }
}