body > header {

    padding-top:    $grid-column-gutter/2;
    padding-bottom: $grid-column-gutter/2;

    h1 {
        height:          12rem + rem-calc(map-get(map-get($header-sizes, small), h1));
        line-height:     12rem + rem-calc(map-get(map-get($header-sizes, small), h1));

        @include breakpoint(medium) {
            height:      12rem + rem-calc(map-get(map-get($header-sizes, medium), h1));
            line-height: 12rem + rem-calc(map-get(map-get($header-sizes, medium), h1));
        }

        background:      #FFFFFF url('/assets/img/fibreparkdigitalacademy1.png') no-repeat center center;
        text-indent:     -9999px;
        background-size: contain;
        margin:          0;
    }

    nav {
        @include grid-row;
        padding-top: $grid-column-gutter/2;
    }

    ul {
        @include menu-base;
        @include menu-expand;
        text-align: center;
    }

    li {

        font-weight: bold;
        a {
            color:   #000000;
            display: block;

            &.is-active {
                background: rgba(51, 51, 51, 0.7);
                color:      #DDDDDD;
            }

            &:hover {
                background: rgba(51, 51, 51, 0.7);
                color:      #DDDDDD;
                transition: all .25s ease-in-out;
            }
        }
    }
}